@import "styles/mixins";

.card {
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.2s ease;
  border-radius: var(--border-radius);
  height: 100%;

  background-color: var(--clr-neutral-100);
  &__imageContainer {
    position: relative;
    width: 100%;
    height: 200px;
  }
  img {
    object-fit: cover;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    flex: 1 1;
    // position: absolute;
    bottom: 0;
    // background: linear-gradient(0deg, black, transparent);
    width: 100%;
    svg {
      font-size: 1.25rem;
      opacity: 0.3;
      transition: opacity 0.2s ease;
      @include smallScreen(){
        display: none;
      }
    }
  }
  &__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 1rem;
  }
  &__category {
    text-transform: capitalize;
    background: var(--clr-primary-300);
    border-radius: 100px;
    padding: 2px 12px;
    font-size: var(--fs-200);
    width: fit-content;
  }
  &:hover {
    svg {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }
}

.slide {

  .card__body {

    position: absolute;
    background: linear-gradient(0deg, black, transparent);
  }
}