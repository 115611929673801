@import "styles/mixins";

.container {
  display: grid;
}

.brandSlider {
  width:100%;
  padding: 1rem 15px 1rem 1rem !important;
  margin-inline: 0em;
  @include tablet() {
    margin: 0;
    padding: 1rem;
  }
}

.prev,
.next {
  opacity:1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next  {
    right: 0;
  }
  @include largeTablet(){
    display:none;
  }

}


.lastSlide {
  a {
    background-color: var(--clr-primary-300)!important;
  }
  color:white;
  font-weight:600;
}

.title {
  display:flex;
  flex-direction: column;
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height:normal;
    &::first-letter {
      text-transform: capitalize;
    }
    @include tablet() {
      font-size: 2rem;
    }
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1em;
  gap: 1em;
  row-gap: 0rem;
  color: var(--clr-neutral-850);

  &__content {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .icon {
      font-size: 3rem;
      color: var(--clr-primary-300);

      @include mobile() {
        font-size: 2.5rem;
      }
    }
  }

}
