@import "styles/mixins";

.specialsSwiper {
  display: grid !important;
  padding: 10px 1em 1em 1em !important;
  margin-inline: 0em !important;
  @include tablet() {
    margin: 0 !important;
  }
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 380px;
    height: 100%;
    text-transform: capitalize;
    @include mobile() {
      width: 280px;
    }
    a {
      width: 100%;
    }
  }
}


.prev,
.next {
  opacity:1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &.prev {
    left: 0;
  }

  &.next  {
    right: 0;
  }
  @include largeTablet(){
    display:none;
  }

}

.lastSlide {
  height: auto;
  a {
    background-color: var(--clr-primary-300);
    color: var(--clr-neutral-100);
    border-radius: var(--border-radius);
    padding: 1rem;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.5rem;
    &:hover {
      background-color: var(--clr-primary-400);
    }
  }
}

.imageContainer {
  height: 200px;
  position: relative;
  @include mobile() {
    // height: 125px;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  // gap: 5px;
}
.tags {
  display: flex;
  width: 100%;
  gap: 10px;
}
.tag {
  font-weight: 600;
  color: var(--clr-primary-300);
  // font-size:0.938rem;
  font-size: 0.875rem;
}

.taxIncluded {
  gap: 3px;
  color: var(--clr-accent-300);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: fit-content;
}

.fallbackTag {
  background-color: var(--clr-primary-300);
  color: var(--clr-neutral-100);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.75rem;
  text-transform: capitalize;
  svg {
    font-size: 2.125rem;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.75rem;
  margin-top: 2em;
  @include largeMobile() {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  row-gap: 0rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1em;

  .icon {
    font-size: 3rem;
    color: var(--clr-primary-300);
    @include mobile() {
      font-size: 2.5rem;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-left: auto;
    padding-bottom: 0.5rem;
    @include tablet() {
      display: none;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  h2 {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: normal;
    // padding-bottom:0.875rem;
    &::first-letter {
      text-transform: capitalize;
    }
    @include tablet() {
      font-size: 2rem;
    }
  }

  @include tablet() {
    padding-left: 0em;
    padding-inline: 0em;
    padding-inline: 1em;
  }
}

.no_results {
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em;
  svg {
    color: var(--clr-danger);
  }
}

.calendarCard {
  background: var(--clr-primary-300);
  color:var(--clr-neutral-100);
  border: 1px solid var(--clr-neutral-250);
  border-radius: var(--border-radius-md);
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 8px;
  display:flex;
  gap:0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight:600;
  svg {
    font-size:3rem;
    color:var(--clr-neutral-100);
  }
  &:hover {
    background:var(--clr-secondary-300);
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
  }
}
